define(
  ["./zuko/cookie", "./zuko/ajax_client", "./zuko/event", "./zuko/logger", "./zuko/field", "./zuko/field/labeller"],
  function(ZukoCookie, ZukoAjaxClient, ZukoEvent, ZukoLogger, ZukoField, ZukoFieldLabeller) {
    "use strict";

    // Based on https://support.google.com/analytics/answer/2795821?hl=en#searchEngine
    var DefaultSearchEngineList = [
      /((\w*\.)+)?google\.(com|ad|ae|com\.af|com\.ag|com\.ai|al|am|co\.ao|com\.ar|as|at|com\.au|az|ba|com\.bd|be|bf|bg|com\.bh|bi|bj|com\.bn|com\.bo|com\.br|bs|bt|co\.bw|by|com\.bz|ca|cd|cf|cg|ch|ci|co\.ck|cl|cm|cn|com\.co|co\.cr|com\.cu|cv|com\.cy|cz|de|dj|dk|dm|com\.do|dz|com\.ec|ee|com\.eg|es|com\.et|fi|com\.fj|fm|fr|ga|ge|gg|com\.gh|com\.gi|gl|gm|gr|com\.gt|gy|com\.hk|hn|hr|ht|hu|co\.id|ie|co\.il|im|co\.in|iq|is|it|je|com\.jm|jo|co\.jp|co\.ke|com\.kh|ki|kg|co\.kr|com\.kw|kz|la|com\.lb|li|lk|co\.ls|lt|lu|lv|com\.ly|co\.ma|md|me|mg|mk|ml|com\.mm|mn|ms|com\.mt|mu|mv|mw|com\.mx|com\.my|co\.mz|com\.na|com\.ng|com\.ni|ne|nl|no|com\.np|nr|nu|co\.nz|com\.om|com\.pa|com\.pe|com\.pg|com\.ph|com\.pk|pl|pn|com\.pr|ps|pt|com\.py|com\.qa|ro|ru|rw|com\.sa|com\.sb|sc|se|com\.sg|sh|si|sk|com\.sl|sn|so|sm|sr|st|com\.sv|td|tg|co\.th|com\.tj|tl|tm|tn|to|com\.tr|tt|com\.tw|co\.tz|com\.ua|co\.ug|co\.uk|com\.uy|co\.uz|com\.vc|co\.ve|vg|co\.vi|com\.vn|vu|ws|rs|co\.za|co\.zm|co\.zw|cat)$/, // Google
      /((\w*\.)+)?aol\.(com|co\.uk)$/, // AOL
      /((\w*\.)+)?bing\.com$/, // Live
      /((\w*\.)+)?yahoo\.com$/, // Yahoo
      /((\w*\.)+)?msn\.com$/, // MSN
      /((\w*\.)+)?duckduckgo\.com$/, // DuckDuckGo
      /((\w*\.)+)?ask\.com$/, // Ask
      /((\w*\.)+)?360\.cn$/, // 360.cn
      /((\w*\.)+)?(alice\.com|aliceadsl\.fr)$/, // Alice
      /((\w*\.)+)?alltheweb\.com$/, // Alltheweb
      /((\w*\.)+)?altavista\.com$/, // altavista.com
      /((\w*\.)+)?search\.auone\.jp$/, // Auone
      /((\w*\.)+)?isearch\.avg\.com$/, // Avg
      /((\w*\.)+)?search\.babylon\.com$/, // Babylon
      /((\w*\.)+)?baidu\.com$/, // Baidu
      /((\w*\.)+)?biglobe\.ne\.jp$/, // Biglobe
      /((\w*\.)+)?bing\.com$/, // Bing
      /((\w*\.)+)?search\.centrum\.cz$/, // Centrum.cz
      /((\w*\.)+)?search\.comcast\.net$/, // Comcast
      /((\w*\.)+)?search\.conduit\.com$/, // Conduit
      /((\w*\.)+)?cnn\.com$/, // CNN
      /((\w*\.)+)?daum\.net$/, // Daum
      /((\w*\.)+)?ecosia\.org$/, // Ecosia
      /((\w*\.)+)?ekolay\.net$/, // Ekolay
      /((\w*\.)+)?eniro\.se$/, // Eniro
      /((\w*\.)+)?globo\.com/, // Globo
      /((\w*\.)+)?go\.mail\.ru$/, // go.mail.ru
      /((\w*\.)+)?goo\.ne\.jp$/, // goo.ne
      /((\w*\.)+)?haosou\.com$/, // haosou.com
      /((\w*\.)+)?search\.incredimail\.com$/, // Incredimail
      /((\w*\.)+)?kvasir\.no$/, // Kvasir
      /((\w*\.)+)?lycos\.(com|de)$/, // Lycos
      /((\w*\.)+)?mamma\.com$/, // Mamma
      /((\w*\.)+)?mynet\.com$/, // Mynet
      /((\w*\.)+)?najdi\.si$/, // Najdi
      /((\w*\.)+)?naver\.com$/, // Naver
      /((\w*\.)+)?search\.netscape\.com$/, // Netscape
      /((\w*\.)+)?szukaj\.onet\.pl$/, // ONET
      /((\w*\.)+)?ozu\.es$/, // Ozu
      /((\w*\.)+)?qwant\.com$/, // Qwant
      /((\w*\.)+)?rakuten\.co\.jp$/, // Rakuten
      /((\w*\.)+)?rambler\.ru$/, // Rambler
      /((\w*\.)+)?search-results\.com$/, // Search-results
      /((\w*\.)+)?search\.smt\.docomo\.ne\.jp$/, // search.smt.docomo
      /((\w*\.)+)?sesam\.no$/, // Sesam
      /((\w*\.)+)?seznam\.cz$/, // Seznam
      /((\w*\.)+)?so\.com$/, // So.com
      /((\w*\.)+)?sogou\.com$/, // Sogou
      /((\w*\.)+)?startsiden\.no$/, // Startsiden
      /((\w*\.)+)?szukacz\.pl$/, // Szukacz
      /((\w*\.)+)?buscador\.terra\.com\.br$/, // Terra
      /((\w*\.)+)?search\.tut\.by$/, // Tut.by
      /((\w*\.)+)?search\.ukr\.net$/, // Ukr
      /((\w*\.)+)?search\.virgilio\.it$/, // Virgilio
      /((\w*\.)+)?voila\.fr$/, // Voila
      /((\w*\.)+)?wp\.pl$/, // Wirtualna Polska
      /((\w*\.)+)?yandex\.(com|ru)$/, // Yandex
      /((\w*\.)+)?yam\.com$/ // Yam
  ];

    /**
     * This constructor can be used to create a new instance of the Zuko tracker. It is not designed
     * to be called on its own, instead it is used internally in `Zuko.trackForm()`.
     *
     * @param {string} slug The "slug" identifier assigned to the form.
     * @param {HTMLElement} target The HTML element which contains your form, within which interactions will be tracked
     * @alias Zuko
     * @typedef {Object} Zuko
     * @constructor
     * @hideconstructor
     */
    function Zuko(slug, target) {
      if (typeof slug === "string") {
        this.slug = slug;
      } else {
        throw new Error("Slug is required");
      }

      this.logger = (ZukoCookie.get("ZukoDebug") === "true") ? new ZukoLogger(ZukoLogger.DEBUG) : new ZukoLogger();
      this.domain = Zuko.getDomain();

      this.client = new ZukoAjaxClient(Zuko.api_urn, {
        logger: this.logger
      });

      /**
       * Are options supported for addEventListener() in this browser?
       * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#safely_detecting_option_support
       * @type {boolean}
       */
      var optionsSupported = false;
      try {
        var testOptions = {
          get passive() {
            optionsSupported = true;
            return false;
          },
        };
        window.addEventListener("test", null, testOptions);
        window.removeEventListener("test", null, testOptions);
      } catch (err) {
        optionsSupported = false; // false by default, but ensure it's false if the above fails.
      }

      // Load the field labelling tool, if the current window was opened by the Zuko app
      if (window.name.match(ZukoFieldLabeller.windowNameFormat)) { // The Zuko app sets the window name
        this.logger.debug('window was opened from Zuko app field labelling');
        var parts = window.name.match(ZukoFieldLabeller.windowNameFormat),
          labellerFormSlug = parts[1], labellerFormUuid = parts[2];
        // Only load the labelling tool if this instance is for the correct form, based on the slug
        if (this.slug === labellerFormSlug) {
          this.logger.debug('loading Zuko field labelling tool');
          this.labeller = new ZukoFieldLabeller(labellerFormUuid, this.logger);
          this.logger.debug('loaded Zuko field labelling tool');
        }
      }

      if (typeof target === "object") {
        this.target = target;

        /**
         * When browsers do not support an options hash on addEventListener, then this parameter is expected to
         * be the boolean useCapture.
         * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#safely_detecting_option_support
         * We default to useCapture: false so that our callback is called on the event bubbling
         * @see https://stackoverflow.com/a/7398447/1788943
         */
        var options = false;

        /**
         * If signal is supported on event listeners, setup an abort controller to allow the event listener to be
         * disabled if the form is found to not be accepted by the platform.
         */
        if (optionsSupported) {
          this.abortController = new AbortController();
          options = { signal: this.abortController.signal };
        }

        Zuko.eventTypes.forEach(function(eventType) {
          this.target.addEventListener(eventType, function(e) {
            try {
              if (e.target) {
                // Only events on elements with valid tag names are tracked
                var field = new ZukoField(e.target);
                if (field.shouldTrack()) {
                  this.trackEvent(e);
                  if (this.labeller) this.labeller.trackField(field.field); // Allows the current field to be labelled

                  if (['INPUT', 'SELECT', 'TEXTAREA'].includes(e.target.tagName) && e.type === 'change') {
                    if (e.target.getAttribute('aria-invalid') && e.target.getAttribute('aria-invalid') === 'false') {
                      this.setupAriaInvalidObserverForField(e.target);
                    } else {
                      this.captureInvalidFieldEvent(e.target);
                    }
                  }
                }
              }
            } catch (e) {
              this.logger.error("Event not tracked: " + e.message);
            }
          }.bind(this), options);
        }.bind(this));

        // Add this instance to a hash of instances on Zuko so that it can later be referenced when stopping all tracking.
        Zuko.instances = Zuko.instances || {};
        if (!Zuko.instances.hasOwnProperty(slug)) Zuko.instances[slug] = this;
      }

      this.attributes = window.zuko && window.zuko.attributes || {};

      // Store a cookie for each form that a visitor has seen
      var visitorFormKey = ["zukoVisitorId",slug].join('-');
      if (!ZukoCookie.get(visitorFormKey)) {
        // No cookie for this visitor on this form, so this must be the first time that this visitor has seen this form
        ZukoCookie.set(visitorFormKey, Zuko.visitorId, Zuko.visitorViewedFormCookieExpiry, this.domain);
        this.attributes["Visitor Type"] = "New";
      } else {
        // There's a cookie for this visitor on this form, so we _could_ assume that they have seen the form before.
        // However, we don't know if this instance is being created to track events that are part of the user's first
        // session on the form, or not. We must therefore allow the processor to work this out, downstream, when the
        // events are grouped into logical sessions. At this point, the absence of the "Visitor Type" attribute is the
        // best we can do.
      }

      // Import data from session cookie into attributes
      this.logger.debug('Attempting to retrieve trafficMedium from session cookie');
      var trafficMedium = ZukoCookie.get('zukoTrafficMedium');
      if (trafficMedium) {
        this.logger.debug('trafficMedium found in session cookie; adding it to attributes');
        this.attributes.trafficMedium = trafficMedium;
      }

      setInterval(function () {
        if (window.zuko && window.zuko.attributes) {
          for (var key in window.zuko.attributes) {
            if (window.zuko.attributes.hasOwnProperty(key)) {
              var value = window.zuko.attributes[key];
              this.setAttribute(key, value);
            }
          }
        }
      }.bind(this), 3000);
    }

    /**
     * Default visitor ID expiry
     * @type {number}
     * @private
     * @memberOf Zuko
     */
    Zuko.visitorIdExpiry = 365; // 1 year in days

    /**
     * Expiry time for the cookie used to store that a visitor has seen a particular form
     * @type {number}
     * @private
     * @memberOf Zuko
     */
    Zuko.visitorViewedFormCookieExpiry = 365; // 1 year in days

    /**
     * Default URN for the Zuko API
     * @type {string}
     * @private
     * @memberOf Zuko
     */
    Zuko.api_urn = "api.zuko.io/v2";

    /**
     * Event types that Zuko will track
     * @see https://developer.mozilla.org/en-US/docs/Web/Events
     * @type {Array}
     * @private
     * @memberOf Zuko
     */
    Zuko.eventTypes = ["input", "click", "change", "focusout"];

    /**
     * Get the current visitor ID from the current Zuko object, or load from a cookie
     *
     * @returns {string} ID of the current visitor
     * @memberOf Zuko
     */
    Zuko.getVisitorId = function() {
      try {
        return Zuko.visitorId || ZukoCookie.get("zukoVisitorId");
      } catch (e) {
        new ZukoLogger().error("Unable to get visitor ID: " + e);
      }
    };

    /**
     * Set the visitor ID on the current Zuko object and store it in a cookie
     *
     * @param {string} visitorId The ID of the current visitor
     * @returns {string} ID of the current visitor
     * @memberOf Zuko
     */
    Zuko.setVisitorId = function(visitorId) {
      try {
        Zuko.visitorId = visitorId;
        return ZukoCookie.set("zukoVisitorId", visitorId, Zuko.visitorIdExpiry, Zuko.getDomain());
      } catch (e) {
        new ZukoLogger().error("Unable to set visitor ID: " + e);
      }
    };

    /**
     * Generate an alphanumeric ID, usually used to generate a unique visitor ID.
     * Low collision risk and not cryptographically secure.
     *
     * @param {number} [length] Length of the ID to generate
     * @returns {string} The generated ID
     * @private
     * @memberOf Zuko
     */
    Zuko.generateID = function(length) {
      length = length || 32;
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for(var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    /**
     * Delete the current visitor's ID from the current Zuko object and remove the cookie
     *
     * @memberOf Zuko
     */
    Zuko.deleteVisitorId = function() {
      try {
        ZukoCookie.erase("zukoVisitorId", Zuko.getDomain());
        delete Zuko.visitorId;
      } catch (e) {
        new ZukoLogger().error("Unable to delete visitor ID: " + e);
      }
    };

    /**
     * Gets the domain of the site up to and including it's public suffix
     *
     * @example
     * Zuko.getDomain("foo.bar.isimo.google.co.uk")
     * // returns "google.co.uk"
     * @returns {string} The highest domain for the current site
     * @param {string} [domain] A domain to try to obtain the domain for. Note: This will only work for the domain that
     *                          the tracking code is running on.
     * @private
     * @memberOf Zuko
     */
    Zuko.getDomain = function(domain) {
      domain = domain || location.hostname;
      var domainParts = domain.split(".");
      var cutoff = domainParts.length - 1;
      var cookieName = "ZukoDomainCheck_" + (new Date()).getTime();
      var isHTTPS = document.location.protocol === 'https:';
      while(cutoff >= 0 && document.cookie.indexOf(cookieName + "=" + cookieName) === -1) {
        domain = domainParts.slice(cutoff).join(".");
        document.cookie = isHTTPS ? cookieName + "=" + cookieName + ";SameSite=None; Secure"+ ";domain=" + domain + ";" :
                                      cookieName + "=" + cookieName + ";domain=" + domain + ";";
        cutoff--;
      }
      var expires = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toUTCString();
      document.cookie = cookieName + "=;expires=" + expires + ";domain=" + domain + ";";
      return domain;
    };

    /**
     * Load up the visitor ID or generate a new one
     * @type {string}
     * @private
     * @memberOf Zuko
     */
    Zuko.visitorId = Zuko.getVisitorId() || Zuko.setVisitorId(Zuko.generateID());

    /**
     * Starts tracking a form on the page. Instantiates a tracking client. One tracking client should be used per form.
     *
     * @example
     * var client = Zuko.trackForm({
     *   target: document.getElementById('MyForm'),
     *   slug: 'my-form'
     * });
     *
     * @param {Object} params A parameters object containing `slug` and `target`
     * @param {string} params.slug The "slug" identifier assigned to the form
     * @param {HTMLElement} [params.target] The HTML element which contains your form, within which interactions will be tracked
     * @memberof Zuko
     * @returns {Zuko} A new Zuko instance
     */
    Zuko.trackForm = function(params) {
      var slug = params.slug;
      var target = params.target;
      try {
        return new Zuko(slug, target);
      } catch(e) {
        new ZukoLogger().error("Unable to track form: " + e);
      }
    };

    /**
     * Tracks the current `utm_medium` parameter. Alternatively attempts to extract the medium for the current session
     * from `document.referrer`, if there is no `utm_medium` parameter.
     * The outcome is stored in a session cookie. If there is no `utm_medium` parameter and
     * the referrer is from the same domain, the medium stored in the cookie is not updated as this traffic is
     * from within the same site.
     * This function is intended to be called on every page of a site, allowing the traffic
     * medium to be tracked on all entrypoints into the site.
     *
     * @memberof Zuko
     */
    Zuko.trackMedium = function() {
      var logger = new ZukoLogger(ZukoCookie.get("ZukoDebug") === "true" ? ZukoLogger.DEBUG : undefined);
      try {
        var trafficMedium;
        var currentDomain = Zuko.getDomain();

        // Check query string
        if (window.URLSearchParams) {
          logger.debug('Attempting to retrieve utm_medium from URLSearchParams');
          var searchParams = new window.URLSearchParams(window.location.search);
          trafficMedium = searchParams.get('utm_medium');
        }

        // Check referrer
        if (!trafficMedium) {
          logger.debug('Attempting to retrieve medium from referrer');
          if (document.referrer.length > 0) {
            var referrerHostname = new URL(document.referrer).hostname;
            var referrerDomain = Zuko.getDomain(referrerHostname);
            if (referrerDomain !== currentDomain) {
              logger.debug('Referrer is from outside the current domain, so work out if it was from a search engine');
              for (var i = 0; i < DefaultSearchEngineList.length; i++) {
                var searchEngineRegExp = DefaultSearchEngineList[i];
                if (referrerHostname.match(searchEngineRegExp)) {
                  logger.debug('Referrer found to be a search engine, so assigning the medium \'organic\'');
                  trafficMedium = 'organic';
                  logger.debug('Breaking out early as there is no point in searching past the first match');
                  break;
                }
              }
              if (!trafficMedium) {
                logger.debug('No search engines have matched, so defaulting to a standard \'referral\' medium');
                trafficMedium = 'referral';
              }
            } else {
              logger.debug('Referrer is part of the same site, so not changing the referrer in the session cookie');
            }
          }
        }

        // Check session cookie
        if (!trafficMedium) {
          logger.debug('Attempting to retrieve a previously tracked medium from session cookie');
          trafficMedium = ZukoCookie.get('zukoTrafficMedium');
        }

        // No medium found from any source
        if (!trafficMedium) {
          logger.debug('There is no utm_medium param or referrer so the visitor arrived on the page by entering the ' +
            'address or from a bookmark, so assigning a the medium of \'none\'');
          trafficMedium = 'none';
        }

        // Store in session cookie for future
        if (trafficMedium) {
          logger.debug('Found a trafficMedium \''+trafficMedium+'\', so storing it in a session cookie ' +
            'for the tracking code to pick up');
          ZukoCookie.set('zukoTrafficMedium', trafficMedium, undefined, currentDomain);
        }
      } catch (e) {
        logger.warn('Caught error so as to not throw an error in the client\'s browser console');
        logger.error(e);
      }
    }

    /**
     * Determines if an event is classed as one which should classify the session as started. e.g. a data-preparing event.
     * This is here so that Session Replay knows when to start recording.
     *
     * TODO: Move this logic to the Session Replay library, as it's not a concern of the Analytics event tracking library.
     *
     * @example Zuko.isSessionStartEvent('This is a custom event'); // true
     * @example Zuko.isSessionStartEvent({type: "change"}) // true
     * @example Zuko.isSessionStartEvent(Zuko.FORM_VIEW_EVENT); // false
     *
     * @param {Event|Object|string} event The event being tracked by Zuko.trackForm
     * @returns {boolean} true|false to indicate if it is a session start event
     *
     * @memberof Zuko
     *
     * @private
     */
    Zuko.isSessionStartEvent = function (event) {
      if (!event) return false;

      if (event.type &&
          (event.type === Zuko.FORM_VIEW_EVENT.type || event.type === Zuko.COMPLETION_EVENT.type) // None starting events
          ) return false;

      return true;
    };

    /**
     * Standardised completion event to be used in sending a form completion event
     *
     * @deprecated This is a legacy version of Zuko.COMPLETION_EVENT to maintain backwards compatibility. See [Zuko's COMPLETION_EVENT property]{@link Zuko#COMPLETION_EVENT}
     * @private
     * @type {{type: string}}
     * @memberOf Zuko
     */
    Zuko.COMPLETION = { type: "completion" };

    /**
     * Standardised completion event to be used in sending a form completion event
     * @type {{type: string}}
     * @memberOf Zuko
     */
    Zuko.COMPLETION_EVENT = { type: "completion" };

    /**
     * Standardised view event to be used when form is viewed.
     * @type {{type: string}}
     * @memberOf Zuko
     */
    Zuko.FORM_VIEW_EVENT = { type: "formView" };

    /**
     * Tracks a custom event for the current session
     *
     * @example
     * client.trackEvent(Zuko.FORM_VIEW_EVENT);
     *
     * @example
     * client.trackEvent({type: 'Address_Postcode: unable to locate address'});
     *
     * @example
     * client.trackEvent(Zuko.COMPLETION_EVENT);
     *
     * @example
     * client.trackEvent("Email validation failed");

     * @param {Event|Object|string} event The event to be tracked
     * @param {string} event.type A short description of the custom event type
     * @returns {Zuko} the Zuko tracking instance
     * @memberof Zuko
     */
    Zuko.prototype.trackEvent = function(event) {
      var replayId;

      if ("ZukoReplay" in window && window.ZukoReplay.recordings) {
        try {
          var RecordInstance = window.ZukoReplay.recordings[this.slug];
          // If this form is being recorded
          if (RecordInstance) {
            // Trigger event on the first session start event
            if (RecordInstance.state === "NotRecording" && Zuko.isSessionStartEvent(event)) {
              document.dispatchEvent(new CustomEvent('sessionStartEvent', { detail: { slug: this.slug } }));
            }

            // Get the replayId when it is ready to be shared for the session
            replayId = RecordInstance.getReplayIdForSession();

            // Trigger event on receiving a completion event
            if (event.type === Zuko.COMPLETION_EVENT.type) {
              document.dispatchEvent(new CustomEvent('completionEvent', { detail: { slug: this.slug } }));
            }
          }
        } catch (e) {
          this.logger.error("Something went wrong handling this event for a recorded session" + e);
        }
      }

      try {
        var callback = function (e) {
          if (e) {
            switch (e.name) {
              case 'NotTrackableError':
                // If the platform is not tracking this form (for whatever reason) we want to stop sending events.
                this.logger.warn(e.message);
                if (ZukoCookie.get("ZukoDebugTracking") === "true") break;
                this.stopTrackingForm();
                break;
              case 'PlatformError':
              case 'UnexpectedResponseError':
              default:
                // By default and in the above explicit cases, we should allow tracking to continue though, as this might just be a transient issue.
                this.logger.error("Unable to create ZukoEvent or send Event: " + e);
            }
          }
        }.bind(this);

        if (typeof event === 'string') {
          this.client.saveEvent(new ZukoEvent({type: event}, this.constructor.visitorId, this.slug, this.domain, this.attributes, replayId),
            callback);
        } else if (event.type === 'focusout') {
          if (event.target.matches(event.target.localName + ':-webkit-autofill') && !this.attributes.autofillTriggered) {
            this.setAttribute('autofillTriggered', 'true');
            this.client.saveEvent(new ZukoEvent({type: 'Autofill triggered'}, this.constructor.visitorId, this.slug, this.domain, this.attributes, replayId),
              callback);
          }
        } else {
          this.client.saveEvent(new ZukoEvent(event, this.constructor.visitorId, this.slug, this.domain, this.attributes, replayId),
            callback);
        }
      } catch (e) {
        this.logger.error("Unable to create ZukoEvent or send Event: " + e);
      }

      return this;
    };

    /**
     * Sets a custom attribute for the current session
     *
     *  @example
     * client
     *  .setAttribute('source', 'Google')
     *  .setAttribute('split_test', '1A')
     *
     * @param {string} key The name of the custom attribute
     * @param {string} value The value for the custom attribute
     * @returns {Zuko} the Zuko tracking instance
     * @memberof Zuko
     */
    Zuko.prototype.setAttribute = function(key, value) {
      try {
        if (!key || value === undefined || key.length < 1) {
          this.logger.error('Attribute key and value are required')
        } else {
          this.attributes[key] = value;
        }
      } catch (e) {
        this.logger.error("Unable to set attribute: " + e);
      }

      return this;
    };

    /**
     * Unsets a custom attribute for the current session
     *
     * @example
     * client.unsetAttribute('source');
     *
     * @param {string} key The attribute's category name to be unset
     * @returns {Zuko} the Zuko tracking instance
     * @memberof Zuko
     */
    Zuko.prototype.unsetAttribute = function(key) {
      try {
        return this.setAttribute(key, null);
      } catch (e) {
        this.logger.error("Unable to unset attribute: " + e);
      }

      return this;
    };

    /**
     * Stops tracking the form which this Zuko instance is currently tracking
     *
     * @example
     * var z = Zuko.trackForm({
     *   target: document.body,
     *   slug: 'my-form',
     * });
     * // Visitor expresses that they do not wish to be tracked anymore
     * z.stopTrackingForm();
     *
     * @memberof Zuko
     */
    Zuko.prototype.stopTrackingForm = function() {
      if (this.abortController) {
        this.logger.info('Stopping tracking');
        this.abortController.abort();
      } else {
        this.logger.info('Unable to stop tracking in this browser as it is not supported');
      }
    }

    /**
     * Stops tracking all currently tracking forms on the page
     *
     * @example
     * var myFormInstance = Zuko.trackForm({
     *   target: document.getElementById('my-form'),
     *   slug: 'my-form',
     * });
     * var myOtherFormInstance = Zuko.trackForm({
     *   target: document.getElementById('my-other-form'),
     *   slug: 'my-other-form',
     * });
     * // Visitor expresses that they do not wish to be tracked anymore
     * Zuko.stopTrackingForms();
     *
     * @memberof Zuko
     */
    Zuko.stopTrackingForms = function () {
      Object
        .values(Zuko.instances || {})
        .map(function (i) {
          i.stopTrackingForm();
        });
    }

    /**
     * The max length of an invalidField message
     * @type {number}
     * @private
     * @memberOf Zuko
     */
    Zuko.invalidFieldMessageMaxLength = 85;

    /**
     * Sends an 'invalidField' event containing the field and validation message where possible
     * Field validity is checked with either of these methods:
     * a. The field's default validation
     * b. The field's aria-invalid property
     *
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} field The target element to check for validation/invalid properties
     * @memberof Zuko
     * @private
     */
    Zuko.prototype.captureInvalidFieldEvent = function (field) {
      try {
        if (!field.validity.valid || field.getAttribute('aria-invalid') === 'true') {
          this.logger.debug('Capturing an invalidField event');

          var errorMessage;

          var errorMessageId = field.getAttribute('aria-errormessage');
          if (errorMessageId) {
            var errorMessageElement = document.getElementById(errorMessageId);
            if (errorMessageElement) {
              var fullText = errorMessageElement.textContent;
              if (fullText) errorMessage = fullText.slice(0, Zuko.invalidFieldMessageMaxLength);
            }
          }
          this.trackEvent({type: 'invalidField', target: field, invalidFieldMessage: errorMessage});
        }
      } catch (e) {
        this.logger.error('Something went wrong attempting to capture an invalid field event' + e);
      }
    };

    /**
     * Creates an observer on a field's aria-invalid=false value
     *
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} field The target element to observe
     * @memberof Zuko
     * @private
     */
    Zuko.prototype.setupAriaInvalidObserverForField = function (field) {
      try {
        if (!this.ariaInvalidObserver) this.ariaInvalidObserver = new MutationObserver(function (mutation, Observer) {
          this.captureInvalidFieldEvent(mutation[0].target);
          Observer.disconnect();
        }.bind(this));

        this.ariaInvalidObserver.observe(field, {
          attributes: true,
          attributeFilter: ['aria-invalid'],
        });
      } catch (e) {
        this.logger.error('Something went wrong setting up an aria-invalid observer' + e);
      }
    }

    return Zuko;
  });
