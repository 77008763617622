/**
 * @module NotTrackableError
 */
define(["./response_error"], function(ResponseError) {
  "use strict";

  /**
   * When the platform is not tracking this form.
   *
   * @param {XMLHttpRequest} resp Request
   * @returns {Error} Error object
   */
  var NotTrackableError = function(resp) {
    return new ResponseError(
      'NotTrackableError',
      resp,
      'Zuko is currently not allowing traffic from this form. ' +
      'This could be because the org has reached its session limit; ' +
      'the form may have been deleted, ' +
      'or the account is no longer active. ' +
      'No more events will be sent for the duration of this session.');
  }

  return NotTrackableError;
});


