/**
 * Represents a form field, adding in some useful information, ready to be sent to the Zuko API
 * @module ZukoField
 */
define(function() {
  "use strict";

  /**
   * Tag names that Zuko will track
   * @type {Array}
   */
  var trackedTagNames = ["BUTTON", "INPUT", "SELECT", "TEXTAREA"];

  /**
   * @param {HTMLElement} element A HTML element
   * @alias module:ZukoField
   * @class
   */
  function ZukoField(element) {
    if (typeof element.closest === 'function' && element.closest('button') && element.tagName !== 'BUTTON') element = element.closest('button');

    if (element instanceof HTMLElement) {
      this.field = { tagName: element.tagName };

      if (element.dataset.zukoTagName) this.field.tagName = element.dataset.zukoTagName.toUpperCase();

      if (element.attributes.hasOwnProperty("type")) {
        this.field.type = element.attributes.getNamedItem("type").value;
      }
      if (element.dataset.zukoType) this.field.type = element.dataset.zukoType;

      if (element.attributes.hasOwnProperty("id")) {
        this.field.id = element.attributes.getNamedItem("id").value;
      }
      if (element.dataset.zukoId) this.field.id = element.dataset.zukoId;

      if (element.attributes.hasOwnProperty("name")) {
        this.field.name = element.attributes.getNamedItem("name").value;
      }
      if (element.dataset.zukoName) this.field.name = element.dataset.zukoName;
    } else {
      throw new Error("ZukoField requires a valid HTML Element");
    }
  }

  /**
   * Return the parsed field object which supports being serialised as a JSON blob
   *
   * @returns {{tagName: (*|string)}} An object representation of the ZukoField
   */
  ZukoField.prototype.toJSON = function() {
    return this.field;
  };

  /**
   * Returns whether or not interactions on this field should be tracked, based on whether the tagName is supported.
   *
   * @returns {boolean} Whether or not interactions on this field should be tracked
   */
  ZukoField.prototype.shouldTrack = function() {
    return trackedTagNames.indexOf(this.field.tagName) > -1;
  };

  return ZukoField;
});
