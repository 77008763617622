/**
 * Holds the logic around managing the field labelling tool for a given Zuko instance
 * @module ZukoFieldLabeller
 */
define(function() {
  "use strict";

  /**
   * A prefix that's added and expected on every message that the labeller and this module are interested in.
   * @type {string}
   */
  var prefix = 'zukoFieldLabeller';

  /**
   * Origin/URL of the labeller app.
   *
   * This is used to load the labeller and also to check that messages received by this code are indeed from the
   * labeller app.
   *
   * @type {string}
   */
  var origin = 'https://field-labelling-tool.zuko.io';

  /**
   * Load the labeller
   *
   * @class
   * @alias module:ZukoFieldLabeller
   * @param {string} formUuid UUID for a given form that the labeller should be loaded for
   * @param {Object} logger Logger instance
   * @constructor
   */
  function ZukoFieldLabeller(formUuid, logger) {
    if (!formUuid) throw new Error('Form UUID is required');
    if (!logger) throw new Error('Logger is required');
    this.logger = logger;
    if (this.frame || document.querySelector('iframe#zukoFieldLabeller')) {
      this.logger.debug('Returning early, as there is already a labelling tool running on the page.')
      return;
    }
    this.frame = document.createElement('iframe');
    Object.assign(this.frame, {id: prefix, src: origin + '?form_uuid=' + formUuid});
    Object.assign(this.frame.style, {height: '0px', border: 'none'}); // Negate default styling
    document.body.appendChild(this.frame);
    this.logger.debug('Added the iframe to the document body');
    window.addEventListener('message', function(message) {
      this.logger.debug('Received a message ' + JSON.stringify({message: message}));
      if (message.origin !== origin) {
        this.logger.debug('Returning early as the message is not for the correct origin');
        return;
      }
      switch((message.data.purpose.match(new RegExp(prefix + '(\\w+)')) || [])[1]) {
        case 'SetFrameStyle':
          this.logger.debug('Received command to set the frame styles');
          Object.assign(this.frame.style, message.data.styles);
          this.logger.debug('Set the frame styles');
          break;
      }
    }.bind(this));
  }

  /**
   * Posts messages down to the labeller app, informing it of which fields Zuko sees interactions with
   *
   * @param {HTMLElement} field The field element
   */
  ZukoFieldLabeller.prototype.trackField = function (field) {
    this.logger.debug('trackField called; dispatching a message to the labeller');
    this.frame.contentWindow.postMessage(
      {purpose: prefix + 'FieldIdentification', field: field},
      origin
    );
    this.logger.debug('dispatched message to the labeller');
    return this;
  }

  /**
   * The format for the name of windows where the labeller should load. The window name should be set from the Zuko app.
   *
   * @type {RegExp}
   */
  ZukoFieldLabeller.windowNameFormat =
    /^zukoFieldLabeller_(.*)_([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})$/;

  return ZukoFieldLabeller;
});
