/**
 * For connecting to the Zuko API via AJAX
 * @module ZukoAjaxClient
 */
define([
  "./event",
  "./logger",
  "./ajax_client/response_error",
  "./ajax_client/client_request_error",
  "./ajax_client/unexpected_response_error",
  "./ajax_client/not_trackable_error",
  "./ajax_client/platform_error",
], function(
  ZukoEvent,
  ZukoLogger,
  ResponseError,
  ClientRequestError,
  UnexpectedResponseError,
  NotTrackableError,
  PlatformError
) {
  "use strict";

  /**
   * @param {string} urn The URN of the API to connect to
   * @param {Object} params Optional hash of parameters
   * @alias module:ZukoAjaxClient
   * @class
   */
  var ZukoAjaxClient = function(urn, params) {
    if (urn) {
      var protocol = ZukoAjaxClient.ssl ? "https" : "http";
      this.url = protocol + "://" + urn + "/event";
    } else {
      throw new Error("ZukoAjaxClient requires a URN to be instantiated.");
    }

    if (params) {
      // If we've been provided with a logger instance. If not, create our own
      if (params.logger) {
        if (params.logger instanceof ZukoLogger) {
          this.logger = params.logger;
        } else {
          throw new Error("Logger must be an instance of ZukoLogger");
        }
      } else {
        // No logger has been provided, so create one just for this class
        this.logger = new ZukoLogger();
      }
    } else {
      // No logger has been provided, so create one just for this class
      this.logger = new ZukoLogger();
    }
  };

  /**
   * Default to using SSL (HTTPS)
   * @type {boolean}
   */
  ZukoAjaxClient.ssl = true;

  /**
   * Where we actually send the formatted hash of data to the API
   *
   * @param {module:ZukoEvent} event an instance of ZukoEvent to send to the API
   * @param {Function} callback Function to be called when the request to save the event has completed.
   */
  ZukoAjaxClient.prototype.saveEvent = function(event, callback) {
    if (!(event instanceof ZukoEvent)) {
      throw new Error("saveEvent requires a ZukoEvent to be passed to it");
    }
    var c = new XMLHttpRequest();

    // Callback for when .send() completes its request
    this.logger.debug("Binding event 'onload' event listener");
    c.onload = function () {
      switch (true) {
        case (c.status < 200):
          // Informational response.
          callback(new UnexpectedResponseError(c));
          break;
        case (c.status >= 200 && c.status < 300 && c.status === 202):
          // Successful response
          if (c.responseText.match(/Zuko does not track traffic from this/)) {
            // We're not tracking this form, so stop sending further events by signalling to the event listener to stop emitting events.
            callback(new NotTrackableError(c));
            break;
          }
          break; // If we've arrived there, the request was successful and the event appears to have been accepted by the platform.
        case (c.status >= 300 && c.status < 400):
          // Redirection response.
          callback(new UnexpectedResponseError(c));
          break;
        case (c.status >= 400 && c.status < 500):
          // Client request error response.
          callback(new ClientRequestError(c));
          break;
        case (c.status >= 500 && c.status < 600):
          // Platform error response.
          callback(new PlatformError(c));
          break;
        case (c.status >= 600):
        default:
          // Unsupported status code.
          callback(new UnexpectedResponseError(c));
          break;
      }
    };

    this.logger.debug("Opening 'GET' connection");
    c.open('GET', this.url + "?" + ZukoAjaxClient.encodeEvent(event), true);

    this.logger.info('Sending event');
    c.send();
  };

  /**
   * URL encodes an event, recursively
   *
   * @param {Object}   event   An event to encode
   * @param {string}   prefix  Prefix to add to a key
   * @returns {string}         Encoded string representing the event
   */
  ZukoAjaxClient.encodeEvent = function(event, prefix) {
    if (!prefix) prefix = '';
    if (prefix === 'field') {
      event = event.toJSON();
    }

    return Object
      .keys(event)
      .map(function(key) {
        var val = event[key];
        if (val !== null && typeof val === 'object') {
          if (prefix) {
            return ZukoAjaxClient.encodeEvent(val, prefix + '[' + key + ']');
          }
          return ZukoAjaxClient.encodeEvent(val, key);
        } else {
          if (prefix) {
            if (prefix === 'attributes' && val === null) {
              return ['unsetAttributes[]', key]
              .map(encodeURIComponent)
              .join('=');
            }
            return [prefix + '[' + key + ']', val]
              .map(encodeURIComponent)
              .join('=');
          }
          return [prefix + key, val]
            .map(encodeURIComponent)
            .join('=');
        }
      }).filter(function(part) {
        return !!part; // Remove empty keys
      })
      .join('&');
  };

  return ZukoAjaxClient;
});
