/**
 * @module ClientRequestError
 */
define(["./response_error"], function(ResponseError) {
  "use strict";

  /**
   * @param {XMLHttpRequest} resp Request
   * @returns {Error} Error object
   */
  var ClientRequestError = function(resp) {
    return new ResponseError('ClientRequestError', resp)
  }

  return ClientRequestError;
});
