/**
 * Storing, retrieving and deleting cookies for the Zuko JS SDK
 * @module ZukoCookie
 */
define(function() {
  "use strict";

  /**
   * Create a cookie with a given name, value and expiry length
   *
   * @param {string} name The name of the cookie to store in the browser
   * @param {string} value Value to store in the cookie
   * @param {number} [days] Amount of days that the cookie should persist until it expires.
   *                        If undefined, a session cookie is created.
   * @param {string} domain Domain on which to set the cookie
   * @returns {string} Value that was requested to be stored in the cookie
   * @alias module:ZukoCookie.set
   */
  var set = function(name, value, days, domain) {
    var parts = [
      name + "=" + value,
      "domain=" + domain,
      "path=/"
    ];
    if (days) {
      days     = days || 365;
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expiryDateTime = date.toUTCString();
      parts.push("expires=" + expiryDateTime);
    }
    if (document.location.protocol === 'https:') {
      parts.push("SameSite=None", "Secure");
    }
    document.cookie = parts.join("; ");
    return value;
  };

  /**
   * Get a cookie with a given name
   *
   * @param {string} name The name of the cookie to retrieve from the browser
   * @returns {?string} Value of the cookie with the given name. Null if it can't be found.
   * @alias module:ZukoCookie.get
   */
  var get = function(name) {
    var nameEQ = name + "=";
    var ca     = document.cookie.split(";");
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while(c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if(c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  /**
   * Delete a cookie with a given name
   *
   * @param {string} name The name of the cookie to delete from the browser
   * @param {string} domain Domain on which to remove the cookie
   * @alias module:ZukoCookie.erase
   */
   var erase = function(name, domain) {
    this.set(name, "", -1, domain);
  };

  return {
    set: set,
    get: get,
    erase: erase
  };
});
