/**
 * @module UnexpectedResponseError
 */
define(["./response_error"], function(ResponseError) {
  "use strict";

  /**
   * @param {XMLHttpRequest} resp Request
   * @returns {Error} Error object
   */
  var UnexpectedResponseError = function(resp) {
    return new ResponseError('UnexpectedResponseError', resp, 'Received an unexpected response: ['+resp.status+']: '+resp.responseText)
  }

  return UnexpectedResponseError;
});


