/**
 * @module PlatformError
 */
define(["./response_error"], function(ResponseError) {
  "use strict";

  /**
   * Platform error response.
   * We shouldn't encounter this. If we do, there must be something wrong with the platform.
   *
   * @param {XMLHttpRequest} resp Request
   * @returns {Error} Error object
   */
  var PlatformError = function(resp) {
    return new ResponseError('PlatformError', resp);
  }

  return PlatformError;
});


