/* eslint no-console: 0 */
/**
 * Logging module used by the Zuko JS SDK to control logging out put from the client
 * @module ZukoLogger
 */
define(function() {
  "use strict";

  /**
   * Output debug, info, warning and errors
   * @type {number}
   */
  ZukoLogger.DEBUG = 0;

  /**
   * Output info, warning and errors
   * @type {number}
   */
  ZukoLogger.INFO = 1;

  /**
   * Output warning and errors
   * @type {number}
   */
  ZukoLogger.WARN = 2;

  /**
   * Output only errors
   * @type {number}
   */
  ZukoLogger.ERROR = 3;

  /**
   * Do not output anything
   * @type {number}
   */
  ZukoLogger.SILENT = 4;

  /**
   * Define a handler for messages, default to console but can be overridden
   */
  /*jshint -W117 */
  ZukoLogger.handler = console;
  /*jshint +W117 */

  /**
   * Default log level for new instances of this class
   * @type {number}
   */
  ZukoLogger.defaultLevel = ZukoLogger.SILENT;

  /**
   * @alias module:ZukoLogger
   * @param {number} level The level for the instance to use
   * @class
   */
  function ZukoLogger(level) {
    if (level === 0) {
      this.level = 0;
    } else {
      this.level = level || ZukoLogger.defaultLevel;
    }
  }

  /**
   * Log debug messages
   *
   * @param {string} msg The message to be logged
   */
  ZukoLogger.prototype.debug = function(msg) {
    if(this.level <= ZukoLogger.DEBUG) {
      this.constructor.handler.debug(msg);
    }
  };

  /**
   * Log info messages
   *
   * @param {string} msg The message to be logged
   */
  ZukoLogger.prototype.info = function(msg) {
    if(this.level <= ZukoLogger.INFO) {
      this.constructor.handler.info(msg);
    }
  };

  /**
   * Log warning messages
   *
   * @param {string} msg The message to be logged
   */
  ZukoLogger.prototype.warn = function(msg) {
    if(this.level <= ZukoLogger.WARN) {
      this.constructor.handler.warn(msg);
    }
  };

  /**
   * Log error messages
   *
   * @param {string} msg The message to be logged
   */
  ZukoLogger.prototype.error = function(msg) {
    if(this.level <= ZukoLogger.ERROR) {
      this.constructor.handler.error(msg);
    }
  };

  return ZukoLogger;
});
