/**
 * @module ResponseError
 */
define([], function() {
  "use strict";

  /**
   * @param {string} name Type of error
   * @param {XMLHttpRequest} resp Request
   * @param {string} msg Error message
   * @returns {Error} Error object
   */
  var ResponseError = function(name, resp, msg) {
    var e = new Error(msg, resp.responseText || resp.statusText);
    e.name = name;
    e.status = resp.status;
    return e;
  }

  return ResponseError;
});
